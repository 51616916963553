<template>
  <div class="workOrder">
    <!-- 新建按钮 -->
    <div class="btnBox"><a-button @click="addWorkOrder" type="primary">+新建工单</a-button></div>
    <div class="workContent">
      <div class="workConLfet">
        <div class="tabsNav">
          <div :class="{ chcekeds: +activeKey === 1 }" @click="tabClick('1')">
            解决中
            <span :class="[+activeKey === 1 ? 'chceked' : 'chceked1']"></span>
          </div>
          <div :class="{ chcekeds: +activeKey === 2 }" @click="tabClick('2')">
            已关闭
            <span :class="[+activeKey === 2 ? 'chceked' : 'chceked1']"></span>
          </div>
        </div>
        <div class="adplace_left_main" :style="{ overflowY: filterList.length > 0 ? 'scroll' : '' }">
          <a-list v-if="filterList.length > 0" :loading="initLoading" position="bottom">
            <a-list-item :class="{ active: current === item.id }" v-for="item in filterList" :key="item.id">
              <a-list-item-meta @click="handleSelect(item)">
                <div slot="title" class="name">{{ item.title }}</div>
                <div class="desc" slot="description">
                  <div class="descriptions">{{ item.description }}</div>
                  <div class="createTime">{{ item.createTime }}</div>
                </div>
              </a-list-item-meta>
            </a-list-item>
            <template slot="loadMore">
              <div
                v-if="!initLoading && !spinning"
                :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
              >
                <a-button @click="onLoadMore">加载更多</a-button>
              </div>
            </template>
          </a-list>
          <a-empty v-else style="height: 40vh; line-height: 45vh" :image="simpleImage" />
        </div>
      </div>
      <a-spin :spinning="loadingCon" style="width: calc(100vw - 420px); box-sizing: border-box">
        <div class="workConRight" v-if="details.id !== undefined">
          <div class="workHead">
            <div class="headLeft">
              <div>工单ID：</div>
              <div>{{ details.id }}</div>
            </div>
            <div v-if="details.sts === 'A'" class="close" @click="closeWorks">关闭工单</div>
          </div>
          <div class="scrollBox">
            <div class="workTitle">
              <h3 style="padding: 20px 0">{{ details.title }}</h3>
              <div style="display: flex; margin-bottom: 10px">
                <div style="width: 105px; margin-right: 20px">用户id</div>
                <span>{{ details.emails }}</span>
              </div>
            </div>
            <div class="workDesc">
              <div class="workDescLeft">问题类型</div>
              <el-radio-group v-model="details.type" size="medium" class="imgBox" disabled>
                <el-radio label="1" border>后台操作</el-radio>
                <el-radio label="2" border>SDK接入</el-radio>
                <el-radio label="3" border>需求反馈</el-radio>
                <el-radio label="4" border>其他问题</el-radio>
              </el-radio-group>
            </div>
            <div class="workDesc" v-if="details.type === '1'">
              <div class="workDescLeft">菜单</div>
              <a-checkbox-group v-model="details.menuList" class="imgBox" disabled>
                <a-checkbox v-for="(item, index) in menuArr" :key="index" :value="item.value">
                  {{ item.name }}
                </a-checkbox>
              </a-checkbox-group>
            </div>
            <div class="workDesc" v-if="details.type === '2'">
              <div class="workDescLeft">操作系统</div>
              <a-checkbox-group v-model="details.menuList" name="radioGroup" class="imgBox" disabled>
                <a-checkbox v-for="(item, index) in menuArr1" :key="index" :value="item.value">
                  {{ item.name }}
                </a-checkbox>
              </a-checkbox-group>
            </div>
            <div class="workDesc" v-if="details.type === '2'">
              <div class="workDescLeft">SDK版本</div>
              <span class="imgBox">{{ details.sdkVersion }}</span>
            </div>
            <div class="workDesc">
              <div class="workDescLeft">问题描述</div>
              <el-input
                type="textarea"
                show-word-limit
                v-model="details.description"
                disabled
                rows="3"
                resize="none" />
            </div>
            <div class="workDesc" v-if="details.imgList && details.imgList.length > 0">
              <div class="workDescLeft">问题截图/压缩包</div>
              <div class="imgBox">
                <div v-for="fit in details.imgList" :key="fit.file + 1">
                  <div class="elImageBox image_box">
                    <el-image
                      style="height: 100px; min-width: 7vw; max-width: 10vw; text-align: center; border-radius: 5px"
                      :src="resourceUrl + fit.file"
                      :preview-src-list="[resourceUrl + fit.file]"
                    >
                    </el-image>
                    <div class="modal">
                      <div class="wrapper">
                        <a-icon
                          class="deleteIcon"
                          type="eye"
                          @click="() => handlePreviewImg(resourceUrl + fit.file, 1)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="workDesc workDescs" v-if="details.zipList && details.zipList.length > 0">
              <div class="workDescLeft"></div>
              <div style="display: flex; flex-direction: column; width: 100%">
                <div class="zipBox" v-for="fit in details.zipList" :key="fit.file + 1">
                  <div style="cursor: pointer"><a-icon type="file-zip" /> {{ fit.file }}</div>
                  <a :href="resourceUrl + fit.file" target="_blank" style="display: flex">
                    <a-button class="btnBox">点击下载文件</a-button>
                  </a>
                </div>
              </div>
            </div>
            <div class="listBox">
              <h3 style="margin-bottom: 20px">客服回复</h3>
              <a-list itemLayout="horizontal" :dataSource="dataSource">
                <a-list-item slot="renderItem" slot-scope="item, index" :key="index">
                  <a-list-item-meta>
                    <!-- 1=客服回复消息，2=用户提问消息 -->
                    <a slot="title">
                      <img :src="item.type === '1' ? logo : users" alt="" />
                      来自
                      <span>{{ item.type === '1' ? title : details.username }}</span>
                      <span v-if="item.type === '1'">的回复 to 提问人</span>
                    </a>
                    <span class="description" slot="description">
                      <span class="security-list-description">{{ item.msg }}</span>
                      <span class="security-list-value">{{ item.createTime }}</span>
                    </span>
                    <template slot="description" v-if="item.imgList && item.imgList.length > 0">
                      <div class="imgBox">
                        <div v-for="fit in item.imgList" :key="fit.file + 1">
                          <div class="elImageBox image_box">
                            <el-image
                              style="
                                height: 100px;
                                text-align: center;
                                border-radius: 5px;
                                max-width: 10vw;
                                min-width: 7vw;
                              "
                              :src="resourceUrl + fit.file"
                              :preview-src-list="[resourceUrl + fit.file]"
                            >
                            </el-image>
                            <div class="modal">
                              <div class="wrapper">
                                <a-icon
                                  class="deleteIcon"
                                  type="eye"
                                  @click="() => handlePreviewImg(resourceUrl + fit.file, 1)"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="zipBox" v-for="fit in item.zipList" :key="fit.file + 1">
                        <div style="cursor: pointer"><a-icon type="file-zip" /> {{ fit.file }}</div>
                        <a :href="resourceUrl + fit.file" target="_blank" style="display: flex">
                          <a-button class="btnBox">点击下载文件</a-button>
                        </a>
                      </div>
                    </template>
                  </a-list-item-meta>
                </a-list-item>
              </a-list>
            </div>
          </div>
          <div v-if="details.sts === 'A' && !openForm" class="workFoot" @click="openForms">
            <div class="workFootL">
              <a-button class="tips">请点击此处唤起回复框</a-button>
            </div>
          </div>
          <div v-if="openForm" class="workFootEdit">
            <a-form-model ref="ruleForm" :model="formquery">
              <a-form-model-item
                label=""
                prop="msg"
                :rules="[{ required: true, message: '请输入问题描述', trigger: 'change' }]"
              >
                <el-input
                  type="textarea"
                  autofocus
                  placeholder="请输入问题描述"
                  v-model="formquery.msg"
                  maxlength="2000"
                  show-word-limit
                  resize="none"
                  rows="3"
                />
              </a-form-model-item>
              <a-form-model-item label="" prop="fileLists">
                <a-upload
                  name="file"
                  :multiple="true"
                  :action="uploadUrl"
                  :headers="headers"
                  @change="handleChange"
                  maxCount="5"
                  accept=".png,.jpg,.jpg,.webp,.zip,.rar"
                  :show-upload-list="{ showDownloadIcon: true, showRemoveIcon: true }"
                >
                  <a-button><a-icon type="upload" /> 点击上传 </a-button>
                </a-upload>
                <div class="uplodDesc">
                  可支持格式：png、jpg、jpg、webp、zip、rar;文件大小要求不超过30M，最多上传5个;截图请尽量完整清晰
                </div>
              </a-form-model-item>
            </a-form-model>
            <div class="workFootR">
              <a-button style="margin-right: 20px" @click="closeSubmit">关闭</a-button>
              <a-button type="primary" @click="submit">提交</a-button>
            </div>
          </div>
        </div>
        <a-empty v-else style="height: calc(100vh - 200px); line-height: 60vh; background: #fff" :image="simpleImage" />
      </a-spin>
    </div>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%; max-height: 600px" :src="previewImage" />
    </a-modal>
    <!-- 关闭工单提示弹窗 -->
    <a-modal v-model="popconfirmVisible" title="提示" :confirm-loading="confirmLoading" @ok="closeWork">
      <div class="batchModel">
        <a-icon class="icon" type="exclamation-circle" />
        <p>关闭后将无法继续回复，是否关闭工单？</p>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'
import { mapState } from 'vuex'
import { getworkOrder, getWorkOrderMsg, addWorkOrderMsg, closeWorkOrder } from '@/api/workOrder'
const logo = require('@/assets/images/logo.png')
const users = require('@/assets/images/users.png')
const simpleImage = require('@/assets/images/noData.png')

export default {
  components: {},
  mixins: [deviceMixin],
  data () {
    return {
      formquery: {},
      fileList: [],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'common/upload',
      activeKey: 1,
      current: '',
      filterList: [],
      query: {
        page: 1,
        limit: 10,
        sts: 'A'
      },
      spinning: false,
      loadingCon: false,
      dataSource: [],
      value: '',
      details: {},
      popconfirmVisible: false,
      confirmLoading: false,
      openForm: false,
      previewImage: '',
      previewVisible: false,
      fileLists: [],
      logo,
      users,
      simpleImage,
      fileSize: [],
      fileSizes: null,
      loading: false,
      initLoading: false,
      menuArr: [
        { name: '首页', value: '1' },
        { name: '媒体管理', value: '2' },
        { name: '聚合管理', value: '3' },
        { name: '广告管理', value: '4' },
        { name: '高级功能', value: '5' },
        { name: '其他', value: '6' }
      ],
      menuArr1: [
        { name: 'Android', value: '1' },
        { name: 'IOS', value: '2' }
      ]
    }
  },
  computed: {
    headers () {
      return {
        Authorization: this.token
      }
    },
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl,
      // 平台名
      title: (state) => state.autoweb.title
    })
  },
  mounted () {
    this.getWorkList()
  },
  beforeDestroy () {},
  methods: {
    // 点击加载更多
    onLoadMore () {
      this.spinning = true
      this.query.page++
      this.getWorkList()
    },
    handleCancel () {
      this.previewVisible = false
    },
    handlePreviewImg (src, type) {
      this.previewImage = src
      this.previewVisible = true
    },
    // 发送消息
    async submit () {
      this.confirmLoading = true
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const query = {
            workOrderId: this.details.id,
            msg: this.formquery.msg,
            fileUrlList: this.fileLists
          }
          const res = await addWorkOrderMsg(query)
          if (res.code === 200) {
            this.getWorkOrderMsg()
            this.formquery = {}
            this.confirmLoading = false
            this.openForm = false
          }
        } else {
          return false
        }
      })
    },
    closeSubmit () {
      this.openForm = false
      this.formquery = {}
    },
    openForms () {
      this.openForm = true
    },
    closeWorks () {
      this.popconfirmVisible = true
    },
    // closeWork关闭工单
    async closeWork () {
      this.confirmLoading = true
      const query = {
        id: this.details.id,
        sts: 'S'
      }
      const resp = await closeWorkOrder(query)
      if (resp.code === 200) {
        this.details = {}
        this.filterList = []
        this.getWorkList()
      }
      this.confirmLoading = false
      this.popconfirmVisible = false
    },
    // 获取后缀
    getExt (filePath) {
      // 获取最后一个.的位置
      var index = filePath.lastIndexOf('.')
      // 获取后缀
      var ext = filePath.substr(index + 1)
      return ext
    },
    // 获取列表
    async getWorkList () {
      this.initLoading = true
      const resp = await getworkOrder(this.query)
      if (resp.code === 200) {
        const allInfo = resp.data
        this.filterList = [...this.filterList, ...allInfo.items]
        this.filterList.map((items, index) => {
          if (items.fileUrlList && items.fileUrlList.length > 0 && items.fileUrlList[0].length > 0) {
            const arr = []
            items.fileUrlList.map((item) => {
              arr.push({
                file: item,
                ext: this.getExt(item)
              })
            })
            items.imgList = arr.filter((tag) => tag.ext !== 'zip' && tag.ext !== 'rar')
            items.zipList = arr.filter((tag) => tag.ext === 'zip' || tag.ext === 'rar')
            items.fileUrlLists = arr
          } else {
            items.fileUrlLists = []
          }
        })
        this.current = this.filterList[0]?.id || 0
        this.details = this.filterList[0] || {}
        this.getWorkOrderMsg()
        this.spinning = this.filterList.length === allInfo.total
      }
      this.initLoading = false
    },
    handleWorkInfo (list) {
      if (list.length > 0) {
        const sts = list[0].workOrderReadSts || 0
        this.$store.dispatch('changeWorkOrderReadSts', sts)
      }
    },
    // 获取消息列表
    async getWorkOrderMsg () {
      this.loadingCon = true
      const query = {
        workOrderId: this.current
      }
      const resp = await getWorkOrderMsg(query)
      if (resp.code === 200) {
        this.dataSource = resp.data || []
        this.dataSource.forEach((items, index) => {
          if (items.fileUrlList && items.fileUrlList.length > 0 && items.fileUrlList[0].length > 0) {
            const arr = []
            items.fileUrlList.map((item) => {
              arr.push({
                file: item,
                ext: this.getExt(item)
              })
            })
            items.imgList = arr.filter((tag) => tag.ext !== 'zip' && tag.ext !== 'rar')
            items.zipList = arr.filter((tag) => tag.ext === 'zip' || tag.ext === 'rar')
            items.fileUrlLists = arr
          } else {
            items.fileUrlLists = []
          }
        })
        this.handleWorkInfo(this.dataSource)
      }
      this.loadingCon = false
    },
    tabClick (data) {
      // A=开启，S=关闭，D=删除
      this.openForm = false
      this.query.page = 1
      this.query.sts = data === '1' ? 'A' : 'S'
      this.activeKey = data
      this.details = {}
      this.filterList = []
      this.getWorkList()
    },
    handleSelect (key) {
      this.current = key.id
      this.details = key
      this.getWorkOrderMsg()
    },
    addWorkOrder () {
      this.$router.push({
        path: `/accountInfo/addWorkOrder`
      })
    },
    handleChange (info) {
      if (info.file.status === 'done') {
        this.fileLists.push(info.file.response.data)
        this.formquery.fileUrlList = this.fileLists
        this.fileSize.push(info.file.size)
        for (let i = 0; i < this.fileSize.length; i++) {
          this.fileSizes += this.fileSize[i]
        }
        const length = this.formquery.fileUrlList.length > 5
        if (length) {
          this.$message.error('最多上传5个文件！')
        }
        const isLt2M = this.fileSizes / 1024 / 1024 < 30
        if (!isLt2M) {
          this.$message.error('文件大小应小于30M!')
        }
        return isLt2M && length
      }
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.workOrder {
  margin-top: 10px;
  min-height: calc(100vh - 130px);
  .btnBox {
    height: 60px;
    background: #fff;
    line-height: 60px;
    padding-left: 20px;
  }
  .workContent {
    margin: 10px;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: hidden;
    .workConLfet {
      border-radius: 5px;
      background-color: #fff;
      width: 400px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 200px);
      padding-bottom: 10px;
      .tabsNav {
        // height: 58px;
        line-height: 58px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 10px;
        .chcekeds {
          color: @primary-color;
        }
        div {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 16px;
          cursor: pointer;
          .chceked1 {
            width: 40px;
            height: 3px;
            background: #fff;
            transition-duration: 0.5s;
          }
          .chceked {
            width: 40px;
            height: 3px;
            background: @primary-color;
            transition-duration: 0.5s;
          }
        }
      }
      .adplace_left_main {
        box-sizing: border-box;
        padding: 0 20px;
        height: calc(100vh - 280px);
        overflow-y: scroll;
        color: #000;
        cursor: pointer;
        .ant-list-split .ant-list-item {
          border-radius: 8px;
          margin-bottom: 10px;
          padding-left: 20px;
          background: #f5f5fc;
          border-bottom: none;
          .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 5px;
          }
          .desc {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 14px;
            color: #000000;
            .descriptions {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 50%;
              width: 200px;
              margin-bottom: 0;
            }
            .createTime {
              width: 48%;
              margin-bottom: 0;
              color: #afafaf;
            }
          }
          .description {
            display: flex;
            justify-content: space-between;
            width: 100%;
            background: none;
            padding: 0 40px;
            color: #333333;
          }
        }
        .active {
          background: #eaefff !important;
        }
        .ant-list-item:hover {
          background: #eaefff;
        }
      }
    }
    .workConRight {
      border-radius: 5px;
      background: #fff;
      margin-left: 10px;
      padding: 20px 0 0;
      background: url(~@/assets/images/workOrderBg.png);
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      height: calc(100vh - 200px);
      min-width: 840px;
      flex: 1 1 0%;
      .workHead {
        display: flex;
        justify-content: space-between;
        color: #333333;
        padding: 0 20px 10px;
        box-shadow: 2px 2px 2px 2px rgba(242, 242, 242, 0.5);
        .close {
          width: 98px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #eaefff;
          border-radius: 5px;
          color: #6782ff;
          cursor: pointer;
        }
      }
      .scrollBox {
        padding-top: 10px;
        margin-bottom: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        .workTitle {
          padding: 0 20px;
        }
        .workDesc {
          display: flex;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 2px 20px;
          .workDescLeft {
            width: 120px;
            margin-right: 20px;
          }
          .zipBox {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 5px 10px;
            .btnBox {
              background: #eaefff;
              border-radius: 70px;
              border: 1px solid #b2c5ff;
              height: 30px;
              line-height: 30px;
              font-size: 12px;
              color: @primary-color;
              padding: 0 10px;
            }
          }
          .imgBox {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: 100%;
            .btnBox {
              background: #eaefff;
              border-radius: 70px;
              border: 1px solid #b2c5ff;
              height: 40px;
              line-height: 40px;
              color: @primary-color;
            }
          }
          .elImageBox {
            &.image_box {
              border: 1px dashed #ccc;
              margin-right: 10px;
              margin-bottom: 10px;
            }
            position: relative;
            display: flex;
            justify-content: center;
            .modal {
              display: none;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.3);
              .wrapper {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
            &:hover .modal {
              display: block;
            }
            .deleteIcon {
              font-size: 20px;
              margin-left: 10px;
              color: #eee;
            }
          }
        }
        .workDescs {
          padding: 0px 20px;
        }
        .listBox {
          padding: 10px 20px;
          height: calc(100vh - 300px);
          .ant-list-split .ant-list-item {
            border-bottom: none;
            padding: 20px;
            box-shadow: 4px 1px 2px 0px rgba(242, 242, 242, 0.5);
            border-radius: 8px;
            border: 1px solid #f2f2f2;
            margin-bottom: 10px;
            .ant-list-item-meta-title {
              img {
                width: 36px;
                height: 36px;
                border-radius: 50%;
              }
            }
            .description {
              display: flex;
              justify-content: space-between;
              width: 100%;
              background: none;
              padding: 0 40px;
              color: #333333;
              .security-list-description {
                width: 80%;
                max-width: 80%;
              }
            }
            .imgBox {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              width: 100%;
              padding: 5px 40px;
            }
            .elImageBox {
              &.image_box {
                border: 1px dashed #ccc;
                margin-right: 10px;
                margin-bottom: 10px;
              }
              position: relative;
              display: flex;
              justify-content: center;
              .modal {
                display: none;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.3);
                .wrapper {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }
              }
              &:hover .modal {
                display: block;
              }
              .deleteIcon {
                font-size: 20px;
                margin-left: 10px;
                color: #eee;
              }
            }
            .zipBox {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding: 5px 40px;
              .btnBox {
                background: #eaefff;
                border-radius: 70px;
                border: 1px solid #b2c5ff;
                height: 30px;
                line-height: 30px;
                font-size: 12px;
                padding: 0 10px;
                color: @primary-color;
              }
            }
          }
        }
      }
      .workFoot {
        padding: 15px 20px;
        border-top: 1px solid #f2f2f2;
        background: #ffffff;
        border-radius: 0 0 5px 5px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px 1px 2px 6px rgba(242, 242, 242, 0.5);
        .workFootL {
          display: flex;
          min-width: 70%;
          .tips {
            color: #999999;
            padding: 10px 30px;
            background: #f2f2f2;
            border-radius: 5px;
            font-size: 12px;
            margin-left: 10px;
          }
        }
        .workFootR {
          max-width: 30%;
          text-align: right;
        }
      }
      .workFootEdit {
        padding: 20px 20px 10px;
        border-top: 1px solid #f2f2f2;
        background: #ffffff;
        border-radius: 0 0 5px 5px;
        box-shadow: 0px 1px 2px 6px rgba(242, 242, 242, 0.5);
        .ant-form-item {
          margin-bottom: 10px;
        }
        .ant-col-20 {
          width: 100% !important;
        }
        .uplodDesc {
          background: #f9f9f9;
          border-radius: 5px;
          padding: 10px;
          margin-top: 5px;
          line-height: 20px;
        }
        .workFootR {
          text-align: right;
        }
      }
    }
  }
}
::v-deep {
  .el-radio.is-bordered {
    margin-right: 10px;
  }
  .el-radio.is-bordered.is-checked {
    background: fade(@primary-color, 10%);
    border-radius: 5px;
    border: 1px solid fade(@primary-color, 10%);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: rgba(103, 130, 255, 0.6);
  }
  .el-radio__input {
    display: none;
  }
}
::v-deep {
  .ant-modal {
    top: 30%;
    .ant-modal-body {
      .batchModel {
        padding: 30px;
        display: flex;
        align-items: center;
      }
      .icon {
        color: #fa8b15;
        font-size: 22px;
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
      }
    }
    .ant-modal-footer {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      border-top: none;
      padding: 14px;
    }
    .ant-btn,
    .ant-btn-primary {
      width: 90px;
    }
  }
}
</style>
